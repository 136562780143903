import React from "react"
import PropTypes from "prop-types"

// Images
import Stars5 from "../assets/images/5-star-rating.svg"
import Stars45 from "../assets/images/4.5-star-rating.svg"

export default function TestimonialItem({
  children,
  stars,
  author,
  company,
  logo,
  url,
}) {
  return (
    <div className="max-w-4xl mx-auto p-4 text-gray-800 bg-white rounded-lg shadow">
      <div className="mb-2">
        <div className="h-3 text-3xl text-left text-zb-brown">„</div>
        <p className="px-4 text-center text-gray-600 text-lg">{children}</p>
        <div
          className="h-3 text-3xl text-right text-zb-brown"
          style={{ marginTop: -10 }}
        >
          ”
        </div>
        <div className="text-center">
          {stars === 4.5 ? (
            <img src={Stars45} width={150} className="mx-auto my-5" />
          ) : (
            <img src={Stars5} width={150} className="mx-auto my-5" />
          )}
          <h5 className="font-bold text-zb-brown">{author}</h5>
          <p className="text-sm text-gray-600">{company}</p>
          {logo && (
            <a href={url} target="_blank" rel="noreferrer">
              <img
                src={logo}
                alt=""
                className="mx-auto mt-2"
                style={{ width: 100 }}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

TestimonialItem.propTypes = {
  children: PropTypes.node.isRequired,
  stars: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  logo: PropTypes.string,
  url: PropTypes.string,
}
