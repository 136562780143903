import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

// Firebase
import { initializeApp } from "firebase/app"
import { getAnalytics, isSupported } from "firebase/analytics"

// Custom Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"
import Card from "../components/card"
import Testimonials from "../components/testimonials"

// Utils
import { useBreakpoint } from "../utils/hooks"

// Images
import HeaderImageBig from "../assets/images/header-bg-crop-3.png"
import HeaderImageMobile from "../assets/images/header-bg.png"
import HeaderImageMedium from "../assets/images/header-bg-crop-5.png"
import SpellCheckIcon from "../assets/images/spellcheck_black_24dp 1.png"
import ExpressKorrekturIcon from "../assets/images/speed_black_24dp 1.png"
import StandardLektoratIcon from "../assets/images/search_black_24dp 1.png"
import ZweitblickLektoratIcon from "../assets/images/hipster-glasses-160h-dark.png"
import ThreeSixtyLektoratIcon from "../assets/images/360_black_24dp 1.png"
import IndividuellesLektoratIcon from "../assets/images/question_mark_black_24dp 1.png"
import Stars5 from "../assets/images/5-star-rating-outlined.svg"
import Lea4 from "../assets/images/lea-4.png"
import Lea5 from "../assets/images/lea-5.png"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4jakqHJfN6plStM65-BrgeYIHjnOTINM",
  authDomain: "zweitblick-aa92b.firebaseapp.com",
  projectId: "zweitblick-aa92b",
  storageBucket: "zweitblick-aa92b.appspot.com",
  messagingSenderId: "459250031535",
  appId: "1:459250031535:web:6fec252190936d8d152d5a",
  measurementId: "G-Y15BGHB1MH",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = isSupported().then(yes => (yes ? getAnalytics(app) : null))

const IndexPage = () => {
  const { breakPoint, width } = useBreakpoint()
  const [headerImage, setHeaderImage] = useState(HeaderImageBig)
  const [headerImageHeight, setHeaderImageHeight] = useState(800)

  useEffect(() => {
    switch (breakPoint) {
      case "lg":
        setHeaderImage(HeaderImageBig)
        break
      case "md":
        setHeaderImage(HeaderImageMedium)
        // setHeaderImageHeight(500)
        break
      case "sm":
        setHeaderImage(HeaderImageMobile)
      default:
        setHeaderImage(HeaderImageMedium)
        break
    }
  }, [breakPoint])

  return (
    <Layout>
      <Seo title="Home" />
      <div className="max-w-screen-md invisible sm:visible">
        <img
          src={headerImage}
          className="top-0 absolute right-0 -z-50  max-h-[650px] 2xl:max-h-[800px]"
          layout="fixed"
        />
      </div>

      {/* Header */}
      <div className="container max-w-6xl 2xl:max-w-[1500px] h-[400px] sm:h-[590px] 2xl:h-[720px] mx-auto">
        <div className="pt-24 sm:pt-32 md:w-2/5 flex flex-col justify-center items-center">
          <StaticImage
            src="../assets/images/header-logo.svg"
            className="self-center -z-40 select-none w-[320px] sm:w-[600px]"
            alt=""
          />
          <Button className="mt-4" small={width < 640}>
            Unverbindlich anfragen
          </Button>
        </div>
      </div>

      {/* Brown in between section */}
      <div className="h-40 sm:h-24 bg-zb-light-brown -mt-1">
        <div className="container px-4 sm:px-auto sm:mx-auto max-w-7xl flex flex-col sm:flex-row justify-center h-full items-center">
          <div>
            <p className="text-center text-lg sm:text-xl 2xl:text-2xl text-zb-brown">
              <span className="text-3xl">„</span>
              eine Expertin [...], die uns mit Rat und Tat zur Seite steht.
              <span className="text-3xl">“</span>
            </p>
          </div>
          <div className="text-center text-blue-700 mt-3 sm:mt-0 sm:ml-6">
            <Link to="/#reviews" className="mt-1 text-sm">
              <img src={Stars5} width={180} className="mb-1 sm:mb-0" />
              <span className=" font-sans">Zu den Reviews</span>
            </Link>
          </div>
        </div>
      </div>

      {/* Lektorat */}
      <div>
        <div className="container mx-auto max-w-6xl 2xl:max-w-[1500px] mt-20">
          {/* Basisleistungen Sektion */}
          <h3 className="text-center text-5xl sm:text-6xl font-bold">
            Das Unternehmen
          </h3>
          <div className="flex flex-col sm:flex-row justify-between mt-16">
            <div className="w-[100%] sm:w-[30%] flex justify-center items-center">
              <img
                src={Lea4}
                className="h-[440px] 2xl:h-[500px] rounded-md ml-0 mt-2"
                alt=""
              />
            </div>
            <div className="w-[90%] sm:w-[60%] mx-auto mt-10 sm:mt-0">
              <p className="text-lg 2xl:text-2xl">
                <b>„Zweitblick“-Lektorat</b> ist ein selbstständiges
                Kleinunternehmen, das im Jahre 2022 von mir, Lea Gremm,
                gegründet wurde. Als studierte{" "}
                <b>Sprach- und Literaturwissenschaftlerin</b> verfüge ich nicht
                nur über fachliche Kompetenzen, sondern auch über fast
                <b> 10 Jahre Berufserfahrung</b> im Lektorat und Korrektorat –
                sowohl im journalistischen und redaktionellen Bereich als auch
                im Hochschul- und Wissenschaftskontext.
                <br />
                <br />
                <b>„Zweitblick“-Lektorat</b> bietet verschiedene Lektorat- und
                Korrektur-Packages für{" "}
                <b>deutsch- und englischsprachige Texte jeder Art</b> an. Egal
                ob es sich um eine Masterarbeit, eine Dissertation, einen
                Webseitentext oder ein Buchmanuskript handelt – mein Ziel ist
                es, deinen Text nicht nur formal zu korrigieren, sondern ihn
                auch bestmöglich stilistisch zu optimieren. Hierbei soll dein
                Text jedoch niemals seinen ganz <b>individuellen Stil</b>{" "}
                verlieren.
                <br />
                <br />
                Mein Korrekturprozess basiert auf einer{" "}
                <b>engen Zusammenarbeit zwischen Lektor und Autor</b>: Mir ist
                es wichtig, das Allerbeste aus deinem Text herauszuholen!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Brown in between section */}
      <div className="h-24 sm:h-44 bg-zb-light-brown w-screen mt-10 sm:mt-32">
        <div className="container mx-auto max-w-6xl 2xl:max-w-[1500px] flex justify-center sm-justify-between h-full items-center">
          <div className="sm:w-2/3 hidden sm:block">
            <p className="text-xl 2xl:text-2xl text-zb-brown">
              Du kannst mich gerne <b>unverbindlich</b> kontaktieren und wir
              überlegen gemeinsam, wie wir deinen Text bestmöglich verbessern
              können.
            </p>
          </div>
          <div className="sm:w-1/3 flex justify-end">
            <Button>Unverbindlich anfragen</Button>
          </div>
        </div>
      </div>

      {/* Leistungen */}
      <div className="container mx-auto max-w-6xl mt-20">
        <h3 className="text-center text-5xl sm:text-6xl font-bold">
          Leistungen
        </h3>
        <div className="mt-20 grid sm:grid-cols-3 grid-rows-2 gap-12 m-10 ">
          <Card
            title="Standard-Korrektur"
            iconSrc={SpellCheckIcon}
            backsideText={`Enthält eine intensive Korrektur der Rechtschreibung,
                Zeichensetzung und Grammatik sowie einen Layout-Check des
                Textes.`}
          />
          <Card
            title="Express 24-Stunden-Korrektur"
            iconSrc={ExpressKorrekturIcon}
            backsideText={`Enthält eine intensive Korrektur der Rechtschreibung,
          Zeichensetzung und Grammatik sowie einen Layout-Check des Textes
          (nur für Texte bis zu 40 Seiten buchbar).`}
          />
          <Card
            title="Standard-Lektorat"
            iconSrc={StandardLektoratIcon}
            backsideText={
              <span>
                Enthält ein Probelektorat von drei Seiten sowie einen intensiven
                Lektoratsdurchgang und ein abschließendes schriftliches Feedback
                zur Sprache und zum Stil des Textes.
              </span>
            }
          />
          <Card
            title={`„Zweitblick“-Lektorat`}
            iconSrc={ZweitblickLektoratIcon}
            backsideText={
              <span>
                Enthält ein Probelektorat von drei Seiten sowie zwei intensive
                Lektoratsdurchgänge und ein abschließendes schriftliches
                Feedback zur Sprache und zum Stil des Textes.
              </span>
            }
          />
          <Card
            title={"360-Grad-Lektorat"}
            iconSrc={ThreeSixtyLektoratIcon}
            backsideText={
              <span>
                Enthält ein Probelektorat von drei Seiten, zwei intensive
                Lektoratsdurchgänge sowie ein persönliches
                Video-Beratungsgespräch.
              </span>
            }
          />
          <Card
            title={"Individuelles Lektorat"}
            iconSrc={IndividuellesLektoratIcon}
            backsideText={
              <span>
                Keines der Packages sagt dir zu? Kein Problem – ich passe mein
                Lektorat gerne deinen Bedingungen an! Schicke mir doch einfach
                eine unverbindliche Anfrage über das Kontaktformular, dann
                können wir gemeinsam ein passendes Format finden, um deine
                Arbeit zu verbessern.
              </span>
            }
          />
        </div>
      </div>

      {/* Testimonials */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <div id="reviews" className="bg-[#FFEFEF] py-10 sm:py-20">
        <h3 className="text-center text-4xl sm:text-6xl font-bold mb-10 sm:mb-16">
          Was Andere über "Zweitblick" sagen
        </h3>
        <Testimonials />
      </div>
      <br />

      {/* Noch unsicher? */}
      <div className="container mx-auto max-w-6xl 2xl:max-w-7xl mt-10 sm:mt-20">
        <div className="flex flex-col sm:flex-row justify-between">
          <div className="sm:w-1/2 flex flex-col justify-between">
            <h3 className="text-4xl sm:text-6xl font-bold text-center sm:text-left mb-20 sm:mb-0">
              Noch unsicher?
            </h3>
            <p className="text-lg 2xl:text-2xl mx-4 sm:mx-0 mt-4 sm:mt-10">
              Du bist noch unsicher, ob ein „Zweitblick“-Lektorat für dich und
              deinen Text geeignet ist oder welches Package du buchen solltest?
              – Kein Problem! Du kannst mich gerne <b>unverbindlich</b>{" "}
              kontaktieren und wir überlegen gemeinsam, wie wir deinen Text
              bestmöglich verbessern können. Mir ist es wichtig, auf deine
              individuellen Wünsche einzugehen. Aus diesem Grund passe ich mein
              Lektorat auch gerne deinen Bedürfnissen an. Während des gesamten
              Korrekturprozesses stehe ich dir selbstverständlich gerne für
              Rückfragen und bei Unklarheiten zur Verfügung.
            </p>
            <div className="flex justify-center mt-10">
              <Button>Kontaktiere mich</Button>
            </div>
          </div>
          <div className="sm:w-1/2 flex justify-end">
            <img
              src={Lea5}
              className="w-4/6 rounded-md mt-20 sm:mt-0 mx-auto sm:mx-0"
            />
          </div>
        </div>
      </div>

      <div className="h-12 sm:h-[200px]" />
    </Layout>
  )
}

export default IndexPage
