import React from "react"

const flipCard = e => {
  e.currentTarget.firstChild.classList.toggle("hidden")
  e.currentTarget.lastChild.classList.toggle("hidden")
}

const routeToFaq = () => {
  if (!window) return
  window.location = "/preise#faq"
}

function Card(props) {
  const { title, iconSrc, backsideText } = props

  return (
    <div
      className="border-2 border-solid border-zb-brown text-center rounded-md  bg-white transition ease-in-out delay-150 hover:scale-110 hover:shadow-lg cursor-pointer"
      onMouseEnter={flipCard}
      onMouseLeave={flipCard}
      onClick={flipCard}
      onKeyDown={() => {}}
    >
      <div name="front-side" className="h-full">
        <div className="h-24 grid content-center justify-center">
          <h4 className="font-bold m-2 text-2xl">{title}</h4>
        </div>
        <hr />
        <div className="flex justify-center">
          <img src={iconSrc} className="m-14" />
        </div>
      </div>
      <div name="back-side" className="hidden p-8 h-full" onClick={routeToFaq}>
        <div className="grid content-center justify-center h-full">
          <p className="text-left text-lg">{backsideText}</p>
          <small className="text-right mt-4">
            (Klicke, um mehr zu erfahren)
          </small>
        </div>
      </div>
    </div>
  )
}

export default Card
