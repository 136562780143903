import React from "react"
import { Link } from "gatsby"

function Button({ children, inverted, small, className }) {
  const originalStyle =
    "border-2 rounded-lg border-zb-brown p-3 px-4 text-zb-brown font-bold text-xl 2xl:text-2xl bg-zb-bg"
  const invertedStyle =
    "border-2 rounded-lg border-white p-3 px-4 text-white font-bold text-3xl"
  const smallStyle =
    "border rounded-lg border-zb-brown p-2 px-4 text-zb-brown text-lg font-bold"

  var style = originalStyle
  if (inverted) style = invertedStyle
  if (small) style = smallStyle

  return (
    <div>
      <Link to="mailto:lea-gremm@zweitblick-lektorat.at?subject=Zweitblick-Lektorat%20Anfrage">
        <button className={style + " " + className}>{children}</button>
      </Link>
    </div>
  )
}

export default Button
