import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Images
import Stars5 from "../assets/images/5-star-rating.svg"
import Stars45 from "../assets/images/4.5-star-rating.svg"

import FellingerLogo from "../assets/images/logos/fellinger.jpg"
import VirtualbadgeLogo from "../assets/images/logos/virtualbadge.svg"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

// import required modules
import { Navigation } from "swiper/modules"

import Item from "./testimonialItem"

export default function App() {
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
      loop={true}
      style={{ maxWidth: 1100, margin: "0 auto" }}
    >
      <SwiperSlide>
        <Item
          stars={5}
          author="Kenny Strubel"
          company=""
          logo={VirtualbadgeLogo}
          url="https://virtualbadge.io"
        >
          Lea hat uns mit ihrem hervorragenden Lektorat sowohl für deutsche als
          auch englische Blogartikel und Landingpages auf Virtualbadge.io
          begeistert. Ihre professionelle Kommunikation und ihre Fähigkeit,
          unsere Anforderungen zu verstehen, haben den gesamten Prozess
          reibungslos und effizient gestaltet. Mit ihrem Auge für Details hat
          sie stilistische und inhaltliche Verbesserungen vorgenommen und somit
          die Qualität unserer Inhalte auf ein neues Niveau gehoben. Lea hat
          sich schnell an verschiedene Schreibstile angepasst und immer
          pünktlich geliefert. Ihre Flexibilität machte die Zusammenarbeit
          äußerst angenehm, und ihre Zuverlässigkeit gab uns Sicherheit. Wir
          empfehlen Lea wärmstens für jedes Lektoratsprojekt und freuen uns auf
          zukünftige Zusammenarbeiten.
        </Item>
      </SwiperSlide>
      <SwiperSlide>
        <Item author="Nina Weiche und Anke Freyer" company="ReMother">
          Wir haben Lea zum Korrekturlesen unserer Druckprodukte, eBooks und
          Webseitentexte engagiert. Da wir einige spezielle Bezeichnungen
          benutzen, für die es keine eindeutigen Rechtschreibregeln gibt, war es
          uns wichtig, eine Expertin an unserer Seite zu wissen, die uns mit Rat
          und Tat zur Seite steht. Lea hat nicht nur alle Unklarheiten im Text
          aufgespürt, sie hat uns einen roten Faden an die Hand gegeben, der uns
          hilft, auch in Zukunft Wortkreationen rechtschreibkonform und
          einheitlich in unseren Texten zu verwenden. Zudem hat Lea einen super
          genauen Blick auf die korrekte Verwendung von Satzzeichen,
          Leerzeichen, Gedankenstrichen, usw. Dank ihrer Korrektur konnten wir
          kurz vor dem Druck noch etliche Fehler beheben, die uns nach
          100-maligem Durchlesen einfach nicht mehr aufgefallen sind (und auch
          nicht im Autokorrekturprogramm angezeigt wurden!). Die Zusammenarbeit
          mit Lea ist konstruktiv und überaus hilfreich. Sie bringt die Dinge
          schnell auf den Punkt und wir wollen ihre Unterstützung nicht mehr
          missen. Ihr Textgespür erleichtert uns das Schreiben und Umsetzen
          nachhaltig!
        </Item>
      </SwiperSlide>
      <SwiperSlide>
        <Item author="Luisa Wipplinger" company="Museum Heppenheim">
          Ich habe Lea zur Korrektur meiner Bachelorarbeit, mehrerer
          öffentlicher Beiträge und wissenschaftlicher Publikationen engagiert.
          Die Kommunikation und Abwicklung verliefen stets einwandfrei und sehr
          professionell. Neben der Korrektur von einfachen Rechtschreib- und
          Grammatikfehlern achtete Lea auch auf Sinn- und Ausdrucksformen.
          Hierbei sind besonders die äußerst konstruktiven Erläuterungen zu
          ihren Korrekturvorschlägen hervorzuheben, sodass man auch das ein oder
          andere lernen konnte. Lea hat einen sehr genauen Blick und wertete
          meine Texte durch ihre Korrekturen enorm auf. Sie arbeitet sehr
          gewissenhaft und ich konnte mich immer auf das Ergebnis verlassen,
          sodass ich ihre Expertise nicht mehr missen möchte!
        </Item>
      </SwiperSlide>
      <SwiperSlide>
        <Item
          author="Mag. Anna Lena Fellinger"
          company="Psychotherapeutin"
          logo={FellingerLogo}
          url="https://www.psychotherapie-fellinger.com/"
        >
          Lea Gremm als Lektorin ist wirklich großartig! Sie korrigiert sehr
          genau und klar, sodass keine Fragen mehr offen bleiben. Nicht nur die
          Grammatik und Rechtschreibung wurden lektoriert, sondern auch
          Textteile markiert, welche noch nicht ganz verständlich waren. Hierzu
          gab es auch immer Verbesserungsvorschläge, das fand ich total
          hilfreich! Auch für Rückfragen stand sie jederzeit zur Verfügung. Ich
          kann Lea Gremm wärmstens empfehlen für das Lektorieren von
          Abschlussarbeiten und Co.!
        </Item>
      </SwiperSlide>
      <SwiperSlide>
        <Item author="Lis G." company="aus Luxemburg">
          Lea hat meine Masterarbeit und auch schon die eine oder andere
          Seminararbeit gewissenhaft korrekturgelesen. Nicht nur
          grammatikalische Fehler und Interpunktionsfehler findet Lea
          ausnahmslos, sondern auch stilistische Schnitzer werden von ihr
          umformuliert oder angemerkt. Was für Studierende ebenfalls nicht
          unwichtig ist, ist, dass Lea ebenfalls auf formale Dinge und
          Einheitlichkeit des Textes achtet. Nach mehrmaligem Lesen der eigenen
          Arbeiten ist man oftmals „fehlerblind“, doch auch die letzten, für
          einen selbst unauffindbaren Fehler sind vor Lea nicht sicher. So hat
          sich die Qualität meiner Masterarbeit, aber auch der Seminararbeiten
          durch ihre Korrekturen sehr gesteigert. Ihre Leistungen wären auch in
          Zukunft für mich unverzichtbar, hätte ich mein Studium nicht
          erfolgreich abgeschlossen – aber wer weiß, vielleicht werde ich ihre
          Korrekturen doch noch einmal benötigen.
        </Item>
      </SwiperSlide>
      <SwiperSlide>
        <Item author="Nora Schloesser" company="" stars={4.5}>
          Lea hat mehrere meiner wissenschaftlichen Arbeiten komplett oder
          auszugsweise lektoriert und dabei in erster Linie auf Grammatik-,
          Orthografie- und Interpunktionsfehler geachtet. Dank ihrer exakten
          Vorgehensweise konnten nahezu alle entbehrlichen Kommas, Tippfehler
          und Adverbien beseitigt werden. Wenn die Interpunktion nicht stimmte
          oder Kommas, Gedankenstriche o.ä. fehlten, wurden die Satzzeichen
          nicht bloß von Lea verbessert bzw. eingefügt, sondern sie gab mir
          oftmals auch eine Erklärung für ihre Korrekturen. Darüber hinaus hat
          sie ebenfalls ein gutes Auge für stilistische Makel: Da Stil und
          Ausdruck eine eher subjektive Sache sind, hat sie den ursprünglichen
          Text nie vollkommen verändert, sondern immer sehr hilfreiche
          Verbesserungsvorschläge gemacht und diese im Korrekturrand angegeben.
          Schließlich fügte sie nach jedem Lektorat ein ausführliches Feedback
          hinzu, was nicht nur bei wissenschaftlichen Arbeiten, sondern auch bei
          literarischen Texten sehr nützlich und fördernd ist.
        </Item>
      </SwiperSlide>
    </Swiper>
  )
}
